import React from "react";
import "./POServices.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/Po/traceability.jpg";

// Main Component for Provenace Service
export default function Provenace() {
  return (
    <>
      {/* Banner Section */}
      <UpdatesBanner
        className="ProvenanceBanner text-white"
        bannerText="Provenance"
      />
      {/* Section listing provenance services */}

      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub offers manufacturing process traceability as a service to brands, ensuring transparency and accountability throughout the production chain. By meticulously documenting every stage of the manufacturing process, from sourcing raw materials to final packaging, AgriProcessingHub empowers brands to uphold quality standards, meet regulatory requirements, and build consumer trust by providing comprehensive insights into product origins and handling."
          title="Manufacturing Process Traceability"
          src={ContractFarmingImg}
        />
      </div>
      {/* Footer Section */}
      <FooterComponent />
    </>
  );
}
