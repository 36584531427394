import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForManufacturers.css";
import { Container } from "@material-ui/core";
import ManufacturerServices from "./ManufacturerServices";
import MSMECoustomerBenefit from "../WebSite/MSMECoustomerBenefit/MSMECoustomerBenefit";
import CollectionProduct from "../CollectionProduct/CollectionProduct";
import AgriEntreprenurCertifications from "../ForAgriEntreprenuer/AgriEntreprenurCertifications";
import CMProcess from "./CMProcess";
import CMProcessCards from "./CMProcessCards";

/* Main page component for brands*/
export default function ForManufactures() {
  return (
    <>
      {/* Banner section */}
      <UpdatesBanner
        className="forManufactures"
        bannerText={[
          <span key="1">
            We Build Brands and help the existing one to Package
          </span>,
          <br key="2" className="d-lg-block d-none"></br>,
          <span key="3">Organic and Traceable Products</span>,
        ]}
        buttonText="Start Now"
        href="/social-cm-marketplace"
      />

      {/* Section displaying brands services */}
      <div className="">
        <Container>
          <ManufacturerServices />
        </Container>
      </div>

      {/* Section showcasing product categories */}
      <div className="main-wrapper2">
        <Container>
          <CollectionProduct title="Our Categories" />
        </Container>
      </div>

      {/* Section highlighting certifications for brands */}
      <div className="">
        <Container>
          <AgriEntreprenurCertifications />
        </Container>
      </div>

      {/* Desktop view: Detailed process section */}
      <div className="main-wrapper2 subSectionWrapper desktop-vis">
        <CMProcess />
      </div>

      {/* Mobile view: Detailed process section */}
      <div className="main-wrapper2 subSectionWrapper desktop-hide">
        <CMProcessCards />
      </div>

      {/* Section outlining benefits for brands */}
      <Container className="mt-4">
        <MSMECoustomerBenefit />
      </Container>

      {/* Footer component */}
      <FooterComponent />
    </>
  );
}
