import React from 'react';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ProcessingSubsection from './ProcessingSubsection';
import "./ProcessingService.css";

// Main component for Growth service
export default function ProcessingService() {
  return (
    <>
    {/* Banner Section */}
     <UpdatesBanner
        className="processingServiceBg"
        bannerText={["Business Growth Service"]}
      />
      {/* Section displaying list of growth services */}
      <ProcessingSubsection />
      {/* Footer Section */}
     <FooterComponent/>
    </>
  )
}
