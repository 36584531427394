import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import IAATab from "../WebSite/IAATab/IAATab";
import "./FinancialService.css";

// Main component for Financial Services Page
export default function FinancialService() {
  const PMSection = (
    <HomeSubSection
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="fieldSupportService5"
      descriptionTitle="Loan"
      description="AgriProcessingHub offers loan facilitation service to processing entrepreneur to acquire machinery and operational technology in the food processing industry."
    />
  );

  return (
    <>
      {/* Banner Section */}
      <UpdatesBanner
        className="financialSupportServiceBanner"
        bannerText="Financial Services"
      />
      {/* List of Finacial Services */}
      <div className="subSectionWrapper">{PMSection}</div>
      {/* Section showing Facilitation Service processing entrepreneur */}
      {
        <div id="MoneyMakingAssets" className="subSectionWrapper pb-0">
          <IAATab />
        </div>
      }
      {/* Footer Section */}
      <FooterComponent />
    </>
  );
}
