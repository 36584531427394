import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AgriEnrepenurJourney from "../AgriEnrepenurJourney/AgriEnrepenurJourney";
import CTA from "../CTA/CTA";
import AgriEntreprenuerServices from "./AgriEntreprenuerServices";
import AgriEntreprenuerChallange from "./AgriEntreprenuerChallange";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgrientrepreneurBenefits from "./AgriEntrepreneurBenefits";
import AgrientrepreneurContribution from "./AgriEntrepreneurContribution";
import CollectionProduct from "../CollectionProduct/CollectionProduct";

// Main component for Processing entrepreneur
export default function ForAgriEntreprenuer() {
  return (
    <>
      {/* Banner section  */}
      <UpdatesBanner
        className="agriEntrepreneur-bg"
        bannerText={[
          <span key="1">Want to become a processing entrepreneur </span>,
          <br key="2" className="d-lg-block d-none"></br>,
          <span key="3"> to monetize unutilized manufacturing capacity </span>,
        ]}
        roleType="NULL"
        buttonText="Start Monetizing Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "PlantOwner");
        }}
        DlBtn="true"
        dlLink="/ComingSoon"
        DownloadText="Download App"
      />

      {/* Section introducing Processing Entrepreneurs and listing relevant products */}
      <div className=" subSectionWrapper mb-0">
        <Container>
          <TitleDescription
            classname="mb-0 mt-0 "
            title="Discovering Processing entrepreneur"
            description=" Processing entrepreneur are micro and small companies that specialize in providing manufacturing services for the following products."
          />
          <CollectionProduct />
        </Container>
      </div>

      {/* Section displaying challenges faced by Processing entrepreneur */}
      <div className="main-wrapper2">
        <AgriEntreprenuerChallange />
      </div>

      {/* Solution Ecosystem Section */}
      <SolutionEcosystem
        type="CM"
        bodycopy="A system to capitalize on underutilized manufacturing capacity and streamline operations to maximize profits"
      />

      {/* Services for Processing entrepreneur */}
      <div className="main-wrapper2">
        <Container>
          <AgriEntreprenuerServices />
        </Container>
      </div>

      {/* Benefits of Processing entrepreneur */}
      <div className="">
        <AgrientrepreneurBenefits />
      </div>

      {/* Journey of an Processing entrepreneur*/}
      <div className="main-wrapper2 subSectionWrapper">
        <Container>
          <AgriEnrepenurJourney />
        </Container>
      </div>

      {/* Contribution of Processing entrepreneur */}
      <Container>
        <AgrientrepreneurContribution />
      </Container>

      {/* Call-to-action for Processing entrepreneur to join Agri Processing Hub */}
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "Production-Manager");
            }}
            href="/auth/3/signup"
            heading="Join AgriProcessingHub and monetize your unused manufacturing capacity today!"
            btnText="Register Now"
          />
        </div>
      </Container>

      {/* Footer component */}
      <FooterComponent />
    </>
  );
}
