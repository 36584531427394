import React from 'react';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import AgentForm from '../AgentForm/AgentForm';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';

/* Main Page component for career page*/
export default function Agent() {
  return (
    <>
      {/* Banner Section*/}
      <UpdatesBanner
        className="AgentBanner"
        bannerText={[
          <span key="1">Become an AgriProcessingHub agent to help Processing entrepreneurs </span>,
          <br key="2" className="d-lg-block d-none"></br>,
          <span key="3"> monetize their unutilized capacities in your region</span>,
        ]}
        bannerbodycopy="To apply for a position as an AgriProcessingHub agent, please fill in the form below."
        href="#AgentForm"
        buttonText5="Apply to become an Agent"
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&pli=1"
        DownloadText="Download App"
      />

      {/* Agent Application Form Section */}
      <div id="AgentForm">
        <AgentForm usertype="Agent" />
      </div>

      {/* Footer Section*/}
      <FooterComponent />
    </>
  );
}
