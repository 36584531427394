import React from "react";
import "./POServices.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/Po/traceability.jpg";

// Main Component for quality assurance service
export default function QualityAssurance() {
  return (
    <>
    {/* Banner Section */}
      <UpdatesBanner
        className="QualityAssuranceBanner text-white"
        bannerText="Quality Assurance"
      />
    {/* Section listing quality assurance services */}
      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description="
          The quality assurance process for each processing entrepreneur is a meticulously vetted system designed to ensure adherence to rigorous quality standards for every production run. This process begins with a comprehensive assessment of the entrepreneur's production facilities and protocols. Each step of the production cycle is scrutinized, from raw material sourcing to final product packaging.
           "
          title="Quality Assurance"
          src={ContractFarmingImg}
        />
      </div>
    {/* Footer Section */}
      <FooterComponent />
    </>
  );
}
