import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

const commonProps = {
  className1: "aboutBgGrey",
};

const PMSection = (
  <>
    <HomeSubSection
      {...commonProps}
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="processingSupport3"
      description="AgriProcessingHub facilitates processing entrepreneur in establishing their own brand by offering a Food Processor Account as part of their services. This comprehensive solution empowers managers to oversee the entire production cycle, from cultivation to processing, fostering brand autonomy and ensuring high-quality, market-ready agricultural products.
"
      descriptionTitle="
Food-Processor"
    />

    <HomeSubSection
      {...commonProps}
      className="rowReverseAbout--mod borderedCards borderedCards--mod"
      className3="processingSupport5"
      description="Advisory & Training is a business growth service offered by AgriProcessingHub, specifically designed for food processing entrepreneurs. This service provides expert guidance and training to help businesses optimize their operations, enhance productivity, and achieve sustainable growth. By leveraging industry insights and best practices, AgriProcessingHub empowers entrepreneurs to navigate challenges and capitalize on opportunities in the competitive food processing sector.
"
      descriptionTitle="
  Advisory & Training

  "
    />

    <HomeSubSection
      {...commonProps}
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="price-discovery-service"
      description="Price Discovery is a service offered by AgriProcessingHub to food processing entrepreneurs, providing them with crucial information about market prices for their products. This service enables businesses to make informed pricing decisions by offering insights into current market trends and pricing dynamics. By utilizing Price Discovery, entrepreneurs can strategically position their products in the market and maximize their profitability.
"
      descriptionTitle="
  Price Discovery

  "
    />

    <HomeSubSection
      {...commonProps}
      className="rowReverseAbout--mod borderedCards borderedCards--mod"
      className3="processingSupport7"
      description="Marketplace is a service offered by AgriProcessingHub that enables food processing entrepreneurs to sell their products. This platform provides businesses with the opportunity to reach a wider audience and increase their sales. By connecting sellers with potential buyers, AgriProcessingHub's Marketplace helps entrepreneurs expand their market presence and grow their customer base.
"
      descriptionTitle="
  Marketplace 

  "
    />
  </>
);

export default function ProcessingSubsection() {
  return (
    <div className="subSectionWrapper" id="FieldSupport">
      <h2 className="container-title text-center">Business Growth Service</h2>
      {PMSection}
    </div>
  );
}
