import React from "react";
import "./POServices.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import PrivateLabelling from "../../images/Po/status.jpg";
// Main Component for POServices
export default function POServices() {
  return (
    <>
      {/*Banner Section */}
      <UpdatesBanner
        className="POBanner text-white"
        bannerText="PO Tracking & Monitoring"
      />
      {/* Section listing POServices services */}

      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="AgriProcessingHub extends its service of Work Order Status to brands, offering real-time monitoring and updates on the progress of their orders throughout the production cycle. By leveraging advanced tracking systems and communication channels, AgriProcessingHub enables brands to stay informed about the status of their work orders, ensuring timely delivery, mitigating potential delays, and fostering efficient collaboration between all parties involved in the manufacturing process.
          "
          title="PO Tracking & Monitoring"
          src={PrivateLabelling}
        />
      </div>
      {/* Footer Section */}
      <FooterComponent />
    </>
  );
}
