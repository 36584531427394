import React, { Component } from "react";
import "./Club.css";
import ReactPaginate from "react-paginate";
import { Container } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

class AgribusinessClub extends Component {
  constructor(props) {
    super();
    this.state = {
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      joinwithus: [],
      filterJoinwithus: [],
      offset: 0,
      clubsPageCount: 1,
      perPage: 6,
      currentPage: 0,
      userTypes: props.type,
      getShow: false,
      showModal: false,
      showClubModal: false,
      showFormModal: false,
      clubname: "",
      userInfoData: [],
      farmgridsearched: "",
      agribusinessName: "",
      roleType: "",
      assetTypes: [],
      edata: [],
      role: {
        roleName: "",
        resources: [],
      },
    };
    this.isComponentMounted = false;
  }

  componentDidMount() {
    // Fetch users when the component is mounted
    this.getUsers();
  }

  componentWillUnmount() {
    // Prevent state updates after unmounting
    this.isComponentMounted = false;
  }

  getUsers() {
    // Iterate over the user types and fetch user details
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.joinClubValues(this.state.userTypes[i]);
    }
  }

  joinClubValues(userTypes) {
    this.isComponentMounted = true;

    // Fetch user data from the API
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.AssetDeveloperValue}/${userTypes}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => {
        if (this.isComponentMounted) {
          if (res.status !== 200) {
            throw new Error("Failed to fetch data");
          }
          return res.json();
        }
      })
      .then((res) => {
        // If response contains users, update the state
        if (res && res.users && Array.isArray(res.users)) {
          this.setState((prevState) => ({
            joinwithus: [...prevState.joinwithus, ...res.users],
            filterJoinwithus: [...prevState.filterJoinwithus, ...res.users],
            clubsPageCount: Math.ceil(
              [...prevState.joinwithus, ...res.users].length /
                this.state.perPage
            ),
          }));
        } else {
          console.log("No users found");
        }
      })
      .catch((err) => console.error("Error:", err));
  }

  /* Clubs Search */
  requestFarmGridSearch(searchedVal) {
    // Filter users based on search input
    const { filterJoinwithus } = this.state;
    const filterData = filterJoinwithus.filter((data) =>
      data.username.toLowerCase().includes(searchedVal.toLowerCase())
    );

    // Update state with filtered results
    this.setState({
      joinwithus: filterData,
      clubsPageCount: Math.ceil(filterData.length / this.state.perPage),
    });
  }

  cancelFarmGridSearch = () => {
    // Reset the search field
    this.setState({
      farmgridsearched: "",
    });

    // Perform search with an empty value to reset the list
    this.requestFarmGridSearch(this.state.farmgridsearched);
  };
  /* End of Clubs Search */

  loginHandler(username) {
    // Store username in local storage
    localStorage.setItem("clubusername", username);
  }

  /************* Pagination *****************/
  handlePageClick = (e) => {
    // Handle pagination click
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };

  openModal = (adminName) => {
    // Fetch user info when opening modal
    this.userInfo(adminName);
  };

  CBOModal = (adminName) => {
    // Fetch assets when opening modal
    this.CBOAssets(adminName);
  };

  agentFormHandler = (clubname) => {
    // Show form modal and set selected business name
    this.setState({
      showFormModal: true,
      agribusinessName: clubname,
    });
  };

  userInfo(adminName) {
    // Fetch user information from API
    fetch(`${window.appConfig.userInfo}${window.appConfig.Relam}${adminName}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // Update state with user info and show modal
        this.setState({
          userInfoData: res.requestInfo,
          showModal: true,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  CBOAssets(adminName) {
    // Fetch role type of the admin
    fetch(
      `${window.appConfig.getRoleType}${window.appConfig.Relam}${window.appConfig.Relam}${adminName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // Update roleType state and fetch assets
        this.setState(
          {
            roleType: res.role,
          },
          () => {
            this.getAllassets(this.state.roleType);
          }
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getAllassets(role) {
    // Fetch assets based on role type
    fetch(
      `${window.appConfig.getRoleTypeResource}${window.appConfig.Relam}${window.appConfig.Relam}${role}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // Update state with asset data and show modal
        this.setState({ role: res.role, showClubModal: true });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  // Close modal handlers
  closeModal = () => this.setState({ showModal: false });
  closeClubModal = () => this.setState({ showClubModal: false });
  closeFormModal = () => {
    this.setState({ showFormModal: false });
  };
  render() {
    const { joinwithus } = this.state;
    const clubsList = joinwithus.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <>
        {/* Main container */}
        <Container>
          <div className="club-container">
            <div id="tendersContainer" className="container-fluid">
              {/* Check if there are clubs to display */}
              {clubsList.length > 0 ? (
                <Row className="justify-content-center">
                  {/* Loop through each club data */}
                  {clubsList.map((joindata, index) => {
                    return (
                      <Col md={6} lg={4} sm={12} className="d-flex mb-3">
                        {/* Club card wrapper */}
                        <div className="w-100 d-flex">
                          <div
                            className="list-container bg-light mb-3"
                            style={{ width: "100%" }}
                          >
                            {/* Card Header containing username and contact info */}
                            <div className="text-left card-header container-heading1 mt-0 mb-0 d-flex justify-content-between">
                              {/* Display club username */}
                              <div className="club-heading">
                                {joindata.username}
                              </div>{" "}
                              <div>
                                {/* Telephone link */}
                                <a
                                  className="text-white me-2"
                                  href={`tel:${joindata.phone}`}
                                >
                                  <BsTelephoneFill />
                                </a>
                                {/* Email link */}
                                <a
                                  className="text-white"
                                  href={`mailto:${joindata.email}`}
                                >
                                  <MdEmail />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
    
                  {/* Pagination component */}
                  <div className="paginationComponent">
                    <ReactPaginate
                      previousLabel={"<<"} // Previous page button
                      nextLabel={">>"} // Next page button
                      breakLabel={"..."} // Break indicator for large paginations
                      breakClassName={"break-me"} // CSS class for break indicator
                      pageCount={this.state.clubsPageCount} // Total number of pages
                      marginPagesDisplayed={2} // Number of margin pages displayed
                      pageRangeDisplayed={5} // Number of pages displayed at a time
                      onPageChange={this.handlePageClick} // Function to handle page click
                      containerClassName={"pagination"} // CSS class for pagination container
                      subContainerClassName={"pages pagination"} // CSS class for sub container
                      activeClassName={"active"} // CSS class for the active page
                    />
                  </div>
                </Row>
              ) : (
                // Display this message if no clubs are found
                <div className="card">
                  <p className="card-body container-heading2 mb-0">
                    {this.props.bodycopy ? (
                      <b>{this.props.bodycopy}</b>
                    ) : (
                      <b> Club's Not Found </b>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Container>
      </>
    );
    
  }
}

export default AgribusinessClub;
