import React from "react";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import CategoryBanner from "../../CategoryBanner/CategoryBanner";

// BulkonMarketplace Marketplace Component
const BulkonMarketplace = () => {
  // Placeholder for category image

  const categoryImage = {};
  return (
    <>
      {/* Category Banner: Displays the marketplace category and products */}

      <CategoryBanner
        bannerMessage={true}
        MarketCategoryType="AGR11"
        categoryImage={categoryImage}
        quote="quote"
      />
      {/* Footer section */}
      <FooterComponent />
    </>
  );
};

export default BulkonMarketplace;
