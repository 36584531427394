import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import prod2 from "../../images/cool.png";
import { Container, Spinner } from "react-bootstrap";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import { NavHashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

// Category Banner Component
const CategoryBanner = ({ MarketCategoryType, categoryImage, quote, services }) => {
  const accessToken = localStorage.getItem("GlobalAccess");

  // State Variables
  const [activeCategory, setActiveCategory] = useState(null);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Reference to track component mount status
  const isMountedRef = useRef(true);

  // Fetch Categories on Component Mount or when MarketCategoryType changes
  useEffect(() => {
    isMountedRef.current = true; 

    const getAllCategories = async () => {
      setLoading(true);
      setError(false);

      try {
        const url = `${window.appConfig.getAllCategoriesBasedonCategoryTypeID}${window.appConfig.Relam}${window.appConfig.TokenRelam}/${window.appConfig.Relam}${MarketCategoryType}`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        };

        // Fetching category data
        const response = await fetch(url, { method: "GET", headers });
        if (!response.ok) throw new Error("Failed to fetch");

        const data = await response.json();

        // Only update state if the component is still mounted
        if (isMountedRef.current) {
          setCategory(data.categoryList);
          setActiveCategory(data.categoryList.length > 0 ? data.categoryList[0] : null);
          setError(data.categoryList.length === 0);
        }
      } catch (err) {
        console.error("Error:", err);
        if (isMountedRef.current) setError(true);
      } finally {
        if (isMountedRef.current) setLoading(false);
      }
    };

    getAllCategories();

    // Cleanup function to prevent state updates on unmounted component
    return () => {
      isMountedRef.current = false;
    };
  }, [MarketCategoryType]);

  return (
    <>
      {/* Banner Section */}
      <div className="serviceContainer ExploreBannerContainer position-relative" id="ExploreBannerContainer">
        <div className="update-banner-container-header position-absolute">
          <NavLink className="text-white text-decoration-none" to="/">
            <h2 className="mb-0">AgriProcessingHub</h2>
          </NavLink>
        </div>
        <div className="gridSection gridSection--mmod1">
          <>
            <h1 className="container-title mb-2 text-white">Ensured Quality | Traceability | Provenance</h1>
            <p className="container-para text-white">Ensure food safety across the globe</p>
          </>
          
          {/* Loading Spinner */}
          {loading ? (
            <div className="loading-container">
              <Spinner animation="border" /> Loading...
            </div>
          ) : category.length ? (
            // List of categories
            <Grid container justifyContent="space-evenly" alignItems="center" spacing={2} direction="row">
              {category.map((e) => (
                <Grid id="complementaryServices_link" className="hoverEffect" item md={2} sm={4} xs={6} key={e.categoryid}>
                  <NavHashLink onClick={() => setActiveCategory(e)} to={`#${e.categoryname}`}>
                    <img src={categoryImage[e.categoryname] || prod2} alt={e.categoryname} />
                    <h4>{e.categoryname}</h4>
                  </NavHashLink>
                </Grid>
              ))}
            </Grid>
          ) : (
            <h2>No category found</h2>
          )}
        </div>
      </div>

      {/* Products Section */}
      <div className="explore-product-wrapper">
        <Container>
          {activeCategory && (
            <div id="tendersContainer" className="container-fluid">
              <h3 className="container-title" id={activeCategory.categoryname}>
                {activeCategory.categoryname}
              </h3>
              <ProcessedProducts 
                quote={quote} 
                key={activeCategory.categoryname} 
                type={activeCategory.categoryname} 
                userType={["FOOD-PROCESSOR"]} 
                services={services} 
              />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default CategoryBanner;
