
import React from "react";
import Community from "../../images/transparent-plastic-bottles-filled-with-yellow-substance (4).jpg";
import QualityAssurance from "../../images/quality-assurance.jpg";
import PO from "../../images/Po/main.jpg"
import Provenance from "../../images/agronomist-examines-growing-melon-seedlings-farm-farmers-researchers-analysis-plant.jpg";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function ManufacturerServices() {
  const services = [
    {
      title: "Manufacturing onDemand (MoD)",
      image: Community,
      href: "/MoD",
    },
   
    // {
    //     title: "onMarketplace",
    //     image: HoneyAsset,
    //     href: "https://web.gbrapp.com/onMarketplace",
    //   },

    //   {
    //     title: "Social onMarketplace",
    //     image: FieldSupport,
    //     href: "https://web.gbrapp.com/socialonMarketplace",
    //   },

      {
        title: "PO Tracking & Monitoring",
        image: PO,
        href: "/PO-Services",
      },
      {
        title: "Provenance",
        image: Provenance,
        href: "/Provenance",
      },
      {
        title: "Quality Assurance",
        image: QualityAssurance,
        href: "/quality-assurance",
      },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0 pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e,i) => {
          return (
            <Col md={3} key={i}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
