import React from "react";
import FarmerImg from "../../../images/gbrPN/PNImg1.webp";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import DTN from "../../../images/GBR Processing (DTN Network) - DTN Network - 26-08-2024 C.gif";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import { Col, Container, Row } from "react-bootstrap";

import FooterComponent from "../Footer/FooterComponent";
import AgribusinessClub from "../../Club/AgribusinessClub";
import ProductionNetworkBenefits from "./ProductionNetworkBenefits";

// Main Component for Our-Network Page
export default function ProductionNetwork() {
  const ContractManufracturer = ["Production-Manager"];
  return (
    <>
      {/* Banner Section */}
      <UpdatesBanner
        className="pnbg text-white"
        bannerTitle="Monetize Platform"
        bannerbodycopy1={[
          <span key="1">
            Network for Processing Entrepreneurs to Monetize the unutilized
            capacities{" "}
          </span>,
          <br key="2" className="d-lg-block d-none"></br>,
          <span key="3">to maximize the profit</span>,
        ]}
        demo="true"
      />
      {/* Section representing Managed Plant Production Network */}
      <div className="mt-5 mb-5">
        <Container>
          <h4 className="container-title text-center mx-md-4 ">
            Managed Plant Production Network{" "}
          </h4>
          <Row className="justify-content-center">
            <Col md={6} sm={12}>
              <img
                className=" w-100 h-100 cursor-initial"
                src={DTN}
                alt="Infographics"
              />
            </Col>
          </Row>
        </Container>{" "}
      </div>
      {/* Section listing network benefits */}
      <ProductionNetworkBenefits />
      {/*  Section listing network beneficiaries   */}
      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">
          BENEFICIARIES OF THE PPN NETWORK
        </h2>
        {/* Processing entrepreneur */}
        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image15"
          description="At AgriProcessingHub, processing entrepreneur play a vital role in monetizing their processing capacity, contributing to the efficient utilization of resources in food processing production
              "
          descriptionTitle="
          Processing entrepreneur "
          src={ProductionManagerImg}
          href1="/auth/3/signin"
          buttoncont1="Sign In"
          href2="/auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "Production-Manager");
          }}
        />
        {/* Section displaying a list of Processing entrepreneur */}
        <div className="subSectionWrapper">
          <h2 className="container-title mb-md-5 text-center">
            Processing entrepreneur
          </h2>
          {/* 
          AgribusinessClub to fetch and display relevant Processing entrepreneur   
      */}
          <AgribusinessClub
            bodycopy="Processing entrepreneur not found"
            type={ContractManufracturer}
          />
        </div>
        {/* AgriMiner */}
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="imageStrCommittee"
          descriptionTitle="
              AgriMiner"
          description="AgriProcessingHub enables individual consultant or processing entrepreneur well-wisher to earn extra earnings anytime, from anywhere by just uploading warehouse and processing outlet details through the app. Once these details gets authenticated and approved by AgriProcessingHub representative, you will get your commission in your application digital wallet and you can transfer from your digital wallet to bank account."
          onClick={() => {
            localStorage.setItem("userType", "AgriMiner");
          }}
          href2="/auth/3/signup"
          buttoncont2="Register"
          href="auth/3/signup"
          src={ProductionManagerImg}
          href1="/auth/3/signin"
          buttoncont1="Sign In"
        />
        {/* Section displaying a list of AgriMiner */}

        <div className="subSectionWrapper">
          <h2 className="container-title mb-md-5 text-center"> AgriMiner</h2>
          {/* AgribusinessClub to fetch and display relevant AgriMiner   */}
          <AgribusinessClub
            bodycopy="AgriMiner not found"
            type={["AgriMiner"]}
          />
        </div>
      </div>
      {/* Footer Section */}
      <FooterComponent />
    </>
  );
}
