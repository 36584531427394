import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./AgriMinerComponent.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import ArrowImg from "../../images/arrow6.png";
import agriMinerImg from "../../images/agriminer-consultant.jpg";
import step1 from "../../images/step1.png";
import step2 from "../../images/step2.png";
import step3 from "../../images/step3.png";
import step4 from "../../images/step4.png";
import step5 from "../../images/step5.png";
import benefit1 from "../../images/beneifit1.png";
import benefit2 from "../../images/beneifit2.png";
import benefit4 from "../../images/beneifit4.png";
import benefit5 from "../../images/beneifit5.png";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Row, Col } from "react-bootstrap";

// Main Component for AgriMiner
export default class AgriMinerComponent extends React.Component {
  stepCardContent = [
    {
      title: "Step 1",
      bodycopy: "Download, install and Login to the App.",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy: "Onboard the processing entrepreneur.",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "Upload the plant details.",
      img: step2,
    },
    {
      title: "Step 4",
      bodycopy: "Wait for the approval from AgriProcessingHub representative.",
      img: step3,
    },
    {
      title: "Step 5",
      bodycopy:
        "Once approved by AgriProcessingHub, you can check the earnings in your wallet. ",
      img: step4,
    },
    {
      title: "Step 6",
      bodycopy:
        "Withdraw money anytime from Wallet to your verified Bank Account.",
      img: step5,
    },
  ];

  benefits = [
    {
      img: benefit1,
      title: "Be Your Own Boss",
      bodycopy:
        "Work for yourself and have more control and flexibility over your work activities and finance.",
    },
    {
      img: benefit2,
      title: "Start earning with Zero Investment",
      bodycopy:
        "Download, install, open and register with AgriProcessingHub Platform to start earning without any investment.",
    },

    {
      img: benefit4,
      title: "Contribute to processing entrepreneur growth",
      bodycopy:
        "Agriminer enhances processing entrepreneur growth by integrating them with AgriProcessingHub, increasing productivity, sustainability, and technological advancement.",
    },
    {
      img: benefit5,
      title: "Make more passive income than ever",
      bodycopy:
        "Easy and quick way to make passive income by reffering processing entrepreneur and other AgriProcessingHub partners of your locality.",
    },
  ];

  render() {
    return (
      <>
        {/* Banner Section */}
        <UpdatesBanner
          bannerText={["Start Earning With No Investment"]}
          className="AgriMinorContainer"
          bannerbodycopy="Earn round the clock by becoming AgriMiner"
          DlBtn="true"
          dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&hl=en_US"
          DownloadText="Download App"
          marginfalse="true"
        />
        {/* Section Explaining Who are an Agriminer */}
        <div className="main-wrapper2">
          <div className=" subSectionWrapper">
            <Container>
              <TitleDescription
                classname="mb-0 mt-0 "
                title="Who are an Agriminer"
                description={[
                  "            An AgriMiner can be any individual consultant or processing entrepreneur well-wisher who want processing entrepreneur to grow its business",
                ]}
              />
            </Container>
          </div>
        </div>
        {/* Section listing AgriMuner Benefits */}
        <div className="agriminer-wrapper">
          <div className="subSectionWrapper ">
            <Container>
              <h4 className="benefit-title container-title  text-center">
                Agriminer Benefits
              </h4>

              <Row className="justify-content-between">
                {this.benefits.map((e, i) => {
                  return (
                    <Col md={3} className="d-flex" key={i}>
                      <BenefitsCard
                        src={e.img}
                        title={e.title}
                        bodycopy={e.bodycopy}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
          {/* Section listing AgriMuner Journey */}

          <div className=" subSectionWrapper main-wrapper2">
            <Container>
              <h4 className="benefit-title container-title  text-center">
                Agriminer Journey
              </h4>

              <Row className="justify-content-between">
                {this.stepCardContent.map((e, i) => {
                  return (
                    <Col md={2} className="d-flex" key={i}>
                      <BenefitsCard
                        src={e.img}
                        title={e.title}
                        bodycopy={e.bodycopy}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
          {/* Registration call-to-action section */}

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod pt-0"
            description=" AgriProcessingHub enables individual consultant or processing entrepreneur well-wisher to earn extra earnings anytime, from anywhere by just uploading warehouse and processing outlet details through the app. Once these details gets authenticated and approved by AgriProcessingHub representative, you will get your commission in your application digital wallet and you can transfer from your digital wallet to bank account. 
                "
            descriptionTitle="
        Sign up as Agriminer !"
            src={agriMinerImg}
            href4="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&hl=en_US"
            buttoncont1="Become a Agriminer"
            btn2="true"
          />
        </div>
        {/* Footer Section */}
        <FooterComponent />
      </>
    );
  }
}
