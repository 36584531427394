import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import WebSiteApp from "./components/WebSite/App";
import Farmer from "./components/Farmer/FarmerComponent";
import About from "./components/AboutUs/AboutusComponent";
import AuthRoute from "./components/Auth/AuthRoute";
import MainRoute from "./components/Main/components/Main/MainRoute";
import BusinessPartner from "./components/BusinessPartner/BusinessPartner";
import { NotificationContainer } from "react-notifications";
import ServicesComponent from "./components/Services/ServicesComponent";
import GbrFormsComponent from "./components/GBRForms/GbrFormsComponent";
import TermsComponent from "./components/T&C/Terms";
import PrivacyComponent from "./components/Privacy/Privacy";
import "react-notifications/lib/notifications.css";
import "./index.css";
import ExploreProducts from "./components/ExploreProducts/ExploreProducts";
import { ScrollToTop } from "./components/ScrollTo/ScrollToTop";
import MainService from "./components/Main/components/Main/Common/MainService";
import ForAgriEntreprenuer from "./components/ForAgriEntreprenuer/ForAgriEntreprenuer";
import Agent from "./components/Agent/Agent";
import CommunityService from "./components/CommunityService/CommunityService";
import AssetService from "./components/AssetService/AssetService";
import FieldSupportService from "./components/FieldSupportService/FieldSupportService";
import ProcessingService from "./components/ProcessingService/ProcessingService";
import ForManufactures from "./components/ForManufactures/ForManufactures";
import ForAAMC from "./components/ForAAMC/ForAAMC";
import ProductionNetwork from "./components/WebSite/ProductionNetwork/ProductionNetwork";
import OnMarketplaceSell from "./components/OnMarketplaceSell/OnMarketplaceSell";
import SocialMarketplace from "./components/SocialMarketplace/SocialMarketplace";
import RFQ from "./components/RFQ/RFQ";
import ManufacturingOnDemand from "./components/ManufacturingOnDemand/ManufacturingOnDemand";
import IAAMarketplace from "./components/IAAMarketplace/IAAMarketplace";
import AgriBusiness from "./components/AgriBusiness/AgriBusiness";
import Brands from "./components/Brands/Brands";
import "./config";
import AAMCNetwork from "./components/WebSite/AAMCNetwork/AAMCNetwork";
import AssetForInvestment from "./components/AssetForInvestment/AssetForInvestment";
import LandingPage from "./components/WebSite/LandingPage/LandingPage";
import Request from "./components/RequestAc/Request";
import FinancialService from "./components/FinancialService/FinancialService";
import POServices from "./components/POServices/POServices";
import Provenace from "./components/POServices/Provenace";
import GBRCareLandingPage from "./components/WebSite/GBRCareLandingPage/GBRCareLandingPage";
import FarmerFinacialService from "./components/FarmerFinacialService/FarmerFinacialService";
import AssetMarketService from "./components/AgriProducers/AssetMarketService/AssetMarketService";
import AgriMinerComponent from "./components/AgriMiner/AgriMinerComponent";
import QualityAssurance from "./components/POServices/QualityAssurance";
import BulkonMarketplace from "./components/ExploreProducts/BulkonMarketplace/BulkonMarketplace";
import SocialCMMarketplace from "./components/SocialCMMarketplace/SocialCMMarketplace";

const { user } = MainService.getLocalData();
const { parentId } = user;

let ConsumerAdmin;
let ClientId;
let ClientSecretKey;
let ClientName;
let AssetverticalID;

if (process.env.REACT_APP_DEVELOPMENT_ENV === "production") {
  ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION;
  ClientId = process.env.REACT_APP_CLIENT_ID_PRODUCTION;
  ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_PRODUCTION;
  ClientName = process.env.REACT_APP_NAME_PRODUCTION;
  AssetverticalID = process.env.REACT_APP_ASSETVERTICAL_PRODUCTION;
} else if (process.env.REACT_APP_DEVELOPMENT_ENV === "staging") {
  ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_STAGING;
  ClientId = process.env.REACT_APP_CLIENT_ID_STAGING;
  ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_STAGING;
  ClientName = process.env.REACT_APP_NAME_STAGING;
  AssetverticalID = process.env.REACT_APP_ASSETVERTICAL_STAGING;
}

window.AppConfigData = {
  ConsumerAdmin: ConsumerAdmin,
  CBNRealm: parentId,
  AssetVerticalId: AssetverticalID,
  AssetVerticalFriendlyName: "ANIMAL",
  ApiUrlMappCBN: window.appConfig.cbn,

  ClientName: ClientName,
  ClientId: ClientId,
  RedirectUrl: "/",
  Realm: parentId,
  EnterpriseId: ConsumerAdmin,
  ClientSecretKey: ClientSecretKey,
  ApiUrlMapp: window.appConfig,
  analytics: {
    google: window.googleAnalytics,
  },
};

const theme = createTheme({
  palette: {
    primary: { main: "#3ECF8E" },
    secondary: { main: "#2F80ED" },
    danger: { main: "#BF360C", shadow: "rgba(191,54,12 ,.3)" },
  },
  status: {
    danger: "orange",
  },
});

try {
  ReactGA.initialize(window.AppConfigData.analytics.google.accountId, {
    debug: false,
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
} catch (e) {
  console.error(e);
}

class IndexComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  createAccessToken() {
    fetch(
      `${window.appConfig.PublicAccessToken}${window.appConfig.TokenRelam}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_name: ClientName,
          client_secret: ClientSecretKey,
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({});
        localStorage.setItem("GlobalAccess", result.access_token);
      })
      .catch((err) => console.log("err", err));
  }
  componentDidMount() {
    this.createAccessToken();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop>
            <Switch>
              {/* Required Routes Start */}
              <Route
                exact
                path="/"
                component={(args) => <ForAgriEntreprenuer {...args} />}
              />
              <Route
                exact
                path="/income-generating-services"
                component={(args) => <AssetService {...args} />}
              />
              <Route
                exact
                path="/financial-services"
                component={(args) => <FinancialService {...args} />}
              />
              <Route
                exact
                path="/GrowthService"
                component={(args) => <ProcessingService {...args} />}
              />
              <Route
                exact
                path="/About"
                component={(args) => <About {...args} />}
              />
              <Route
                exact
                path="/ppn-network"
                component={(args) => <ProductionNetwork {...args} />}
              />
              <Route
                exact
                path="/agriminer"
                component={(args) => <AgriMinerComponent {...args} />}
              />
              <Route
                exact
                path="/ForBrands"
                component={(args) => <ForManufactures {...args} />}
              />
              <Route
                exact
                path="/Careers"
                component={(args) => <Agent {...args} />}
              />
              <Route
                exact
                path="/Terms&Conditions"
                component={(args) => <TermsComponent {...args} />}
              />
              <Route
                exact
                path="/PrivacyPolicy"
                component={(args) => <PrivacyComponent {...args} />}
              />
              <Route
                path="/auth"
                component={(args) => <AuthRoute {...args} />}
              />
              <Route
                path="/main"
                component={(args) => <MainRoute {...args} />}
              />
              <Route
                exact
                path="/bulk-onmarketplace"
                component={(args) => <BulkonMarketplace {...args} />}
              />
              <Route
                exact
                path="/social-cm-marketplace"
                component={(args) => <SocialCMMarketplace {...args} />}
              />
              <Route
                exact
                path="/MoD"
                component={(args) => <ManufacturingOnDemand {...args} />}
              />
              <Route
                exact
                path="/PO-Services"
                component={(args) => <POServices {...args} />}
              />
              <Route
                exact
                path="/Provenance"
                component={(args) => <Provenace {...args} />}
              />
              <Route
                exact
                path="/quality-assurance"
                component={(args) => <QualityAssurance {...args} />}
              />
              {/* Required Routes End */}

              <Route
                exact
                path="/ForFarmers"
                component={(args) => <GBRCareLandingPage {...args} />}
              />
              <Route
                exact
                path="/agri-storage-entrepreneurs"
                component={(args) => <WebSiteApp {...args} />}
              />
              <Route
                exact
                path="/Farmer"
                component={(args) => <Farmer {...args} />}
              />
              <Route
                exact
                path="/ComingSoon"
                component={(args) => <Request {...args} />}
              />
              <Route
                exact
                path="/onMarketplace"
                component={(args) => <ExploreProducts {...args} />}
              />
              <Route
                exact
                path="/EngagementServices"
                component={(args) => <GbrFormsComponent {...args} />}
              />
              <Route
                exact
                path="/Services"
                component={(args) => <ServicesComponent {...args} />}
              />
              <Route
                exact
                path="/Community"
                component={(args) => <CommunityService {...args} />}
              />
              <Route
                exact
                path="/home"
                component={(args) => <LandingPage {...args} />}
              />
              <Route
                exact
                path="/Field-Support"
                component={(args) => <FieldSupportService {...args} />}
              />
              <Route
                exact
                path="/ForAAMC"
                component={(args) => <ForAAMC {...args} />}
              />
              <Route
                exact
                path="/PNNetwork"
                component={(args) => <ProductionNetwork {...args} />}
              />
              <Route
                exact
                path="/AAMC"
                component={(args) => <AAMCNetwork {...args} />}
              />
              <Route
                exact
                path="/onMarketplaceSell"
                component={(args) => <OnMarketplaceSell {...args} />}
              />
              <Route
                exact
                path="/SocialCFonMarketplace"
                component={(args) => <SocialMarketplace {...args} />}
              />
              <Route
                exact
                path="/RFQ"
                component={(args) => <RFQ {...args} />}
              />
              <Route
                exact
                path="/AgriBusiness"
                component={(args) => <AgriBusiness {...args} />}
              />
              <Route
                exact
                path="/Brands"
                component={(args) => <Brands {...args} />}
              />
              <Route
                exact
                path="/iaaMarketplace"
                component={(args) => <IAAMarketplace {...args} />}
              />
              <Route
                exact
                path="/InvestableAssets"
                component={(args) => <AssetForInvestment {...args} />}
              />
              <Route
                exact
                path="/farmers-financial-services"
                component={(args) => <FarmerFinacialService {...args} />}
              />

              <Route component={(args) => <Redirect to={"/"} />} />
            </Switch>
          </ScrollToTop>
        </Router>
        <NotificationContainer />
      </ThemeProvider>
      // </StyledEngineProvider>
    );
  }
}

ReactDOM.render(<IndexComponent />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
