import React from "react";
import StepCards from "../stepCards/StepCards";
import step2 from "../../images/ae-journey/ae2.png";
import step1 from "../../images/FarmersJourney/phone-call (1).svg";
import step5 from "../../images/labeling.svg";
import step4 from "../../images/ae-journey/ae1.png";
import step3 from "../../images/step2.png";
import img5 from "../../images/ae-journey/ae5.png";

export default function AgriEnrepenurJourney() {
  const stepCardContent = [
    {
      title: "Step 1",
      bodycopy:
        " Contact a AgriProcessingHub agent to initiate an account or request the creation of an account.",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy:
        "Provide the required identification and address proof documents to finalize your account creation.",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "Download the application and login to your account.",
      img: step3,
    },
    {
      title: "Step 4",
      bodycopy: "Pick and choose the services from the app you want.",
      img: step4,
    },
    {
      title: "Step 5",
      bodycopy: "Manage private labelling and packaging.",
      img: step5,
    },
    {
      title: "Step 6",
      bodycopy: "Share report with AgriProcessingHub.",
      img: img5,
    },
  ];
  return (
    <>
      <h2 className="text-center container-title mb-4">
        Processing entrepreneur Journey
      </h2>
      <div className="section3BodyContainer justify-content-md-center p-0">
        {stepCardContent.map((e, i) => {
          return (
            <StepCards
              key={i}
              src={e.img}
              stepTitle={e.title}
              stepContent={e.bodycopy}
            />
          );
        })}{" "}
      </div>
    </>
  );
}
