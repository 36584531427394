import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent"; // Footer component for additional navigation
import CategoryBanner from "../CategoryBanner/CategoryBanner"; // Banner component to display marketplace details

// Social Contract Manufacturing Marketplace Component
const SocialCMMarketplace = () => {

  // Placeholder for category image 
  const categoryImage = {};

  return (
    <>
      {/* Category Banner: Displays the marketplace category and products */}
      <CategoryBanner 
        MarketCategoryType="AGR15"  
        categoryImage={categoryImage} 
        quote="quote" 
        services="true" 
      />

      {/* Footer Section */}
      <FooterComponent />
    </>
  );
};

export default SocialCMMarketplace;
