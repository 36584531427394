import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import "./HeaderComponent.css";
import React, { useEffect, useState } from "react";
import { List, ListItem, Drawer, IconButton, Link } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../images/logo.png";
import Logo1 from "../../../images/Asset 1@4x.png";
import { NavLink } from "react-router-dom";
const useStyles = makeStyles({
  drawer: {
    width: 250,
  },
});

export default function HeaderComponent({ className }) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [trigger, setTrigger] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 200) {
      setTrigger(true);
    } else {
      setTrigger(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const classes = useStyles();

  const loginHandler = (username) => {
    localStorage.setItem("userType", username);
  };
  return (
    <AppBar
      className={`${
        trigger ? "gbr_header boxShadow" : "gbr_header"
      } ${className}`}
    >
      <Toolbar>
        {/* <Typography className="logo navTitle"> */}
        <div className="logo navTitle">
          <NavLink to="/" exact={true}>
          <h2 className="mb-0">AgriProcessingHub</h2> 

            {/* <img className="logo1" src={Logo} alt="Logo" /> */}
          </NavLink>

          

          {/* <NavLink className="mobile-show" to="/">
            <img className="logo1" src={Logo1} alt="Logo" />
          </NavLink> */}
        </div>
        {/* </Typography> */}
        {/* <div className="desktopNavbar">
         
          <NavLink to="/" exact={true}>
          Home
          </NavLink>
          <NavLink to="/customized-dtn-network">Our Network</NavLink> */}
          {/* <Button className="whiteTextColor">
            <a href="/agristack">Technology</a>
          </Button> */}

          {/* <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            Agri-MSMEs
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock={true}
            className="mt-4"
          >
            <MenuItem onClick={handleClose} component={NavLink} to="/agri-entrepreneur">
              Agri-Entrepreneur
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={NavLink}
              to="/agri-storage-entrepreneurs"
            >
              Agri-Storage Entrepreneur
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={NavLink}
              to="/processing-entrepreneur"
            >
              Agri-Contract Manufacturer
            </MenuItem>
            <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/agri-retailers"
              >
                Agri-Retailers
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/agri-transporters"
              >
                Agri-Transporter
              </MenuItem>
          </Menu> */}

          {/* <NavLink to="/ForAgrientrepreneur">Agri-Entrepreneur</NavLink> */}
          {/* <NavLink to="/ForBrands">Brands</NavLink>

          <NavLink to="/Agent">Agent</NavLink> */}

          {/* <NavLink to="/Pricing">Pricing</NavLink> */}
          {/* <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div> */}
          {/* <Button className="whiteTextColor">
            <a href="/businesspartner">Partner</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/AgriMiner">AgriMiner</a>
          </Button> */}
          {/* <Button className="whiteTextColor">
            <a href="/Investments">Investments</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/Investor">Investor</a>
          </Button> */}
          {/* <Button className="whiteTextColor"> */}
          {/* <NavLink to="/auth/3/signin">Sign In</NavLink> */}
          {/* <NavLink
            to={{ pathname: "auth/3/signup", state: { customData: "present" } }}
          >
            Sign Up
          </NavLink> */}
          {/* </Button> */}
        {/* </div> */}
        {/* <IconButton
          className="menuIcon"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setIsDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton> */}

        {/* <Drawer
          className="drawer"
          anchor={"right"}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <span
            className="iconCnt header-cross-icon closeCnt"
            onClick={() => setIsDrawerOpen(false)}
          >
            <i className="material-icons">close</i>
          </span>
          <br></br>
          <List className={classes.drawer}>
         

            <Button className="whiteTextColor d-block ps-4">
          
              <NavLink exact={true} to="/">
                Home
              </NavLink>
            </Button>

            <Button className="whiteTextColor d-block ps-4">
          
          <NavLink exact={true} to="/customized-dtn-network">
Our Network          </NavLink>
        </Button>

            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/About">About Us</NavLink>
            </Button> */}
            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/ForAgrientrepreneur">Agri-Entrepreneur</NavLink>
            </Button> */}
            {/* <Button
              className="navDrop d-block ps-4 "
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              Agri-MSMEs
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disableScrollLock={true}
              className="mt-5 mx-3"
            >
              <MenuItem onClick={handleClose} component={NavLink} to="/agri-entrepreneur">
                Agri-Entrepreneur
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/agri-storage-entrepreneurs"
              >
                Agri-Storage Entrepreneur
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/processing-entrepreneur"
              >
                Agri-Contract Manufacturer
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/agri-retailers"
              >
                Agri-Retailers
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/agri-transporters"
              >
                Agri-Transporters
              </MenuItem>
            </Menu> */}
            {/* </ListItem> */}
            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/ForBrands">Brands</NavLink>{" "}
            </Button> */}
 {/* <Button className="whiteTextColor d-block ps-4">
              <a href="/ForBrands">Brands</a>
            </Button>
            <Button className="whiteTextColor d-block ps-4">
              <a href="/Agent">Agent</a>
            </Button> */}

            {/* <ListItem button> */}

            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/Pricing">Pricing</NavLink>
            </Button> */}

            {/* <ListItem button>
              <Button className="whiteTextColor">
                <a href="/businesspartner">Partner</a>
              </Button>
            </ListItem>

            <ListItem button>
              <Button className="whiteTextColor">
                <a href="/AgriMiner">AgriMiner</a>
              </Button>
            </ListItem> */}
            {/* <ListItem button>
              <Button className="whiteTextColor">
                <a href="/Investments">Investments</a>
              </Button>
            </ListItem> */}
            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/auth/3/signin">Sign In</NavLink>
            </Button> */}
            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink
                to={{
                  pathname: "auth/3/signup",
                  state: { customData: "present" },
                }}
              >
                {" "}
                Sign Up
              </NavLink>
            </Button> */}
          {/* </List>
        </Drawer> */} 
      </Toolbar>
    </AppBar>

    /* <AppBar className="gbr_header" variant="outlined" position="static">
        <Toolbar>
          <Typography variant="h6" className="logo navTitle">
            <Hidden only={["xs"]}>
              <a href="/">Growlivestock</a>
            </Hidden>

            <Hidden smUp>
              <a href="/">GBR</a>
            </Hidden>
          </Typography>
          

          <Button className="whiteTextColor">
            <a href="/agristack">Technology</a>
          </Button>
          
          <Button className="whiteTextColor">
            <a href="/businesspartner">Partner</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/Producer">Become a Producer</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/becomeBuyer">Become a Buyer</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/Investments">Investments</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/auth/3/signin">Invest Now</a>
          </Button>
          
        </Toolbar>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose.bind(this, -1)}
          PaperProps={{
            style: {
              
              width: 200,
            },
          }}
        >
          <MenuItem onClick={this.handleClose.bind(this, 1)}>
            Investor App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 14)}>
            Grower App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 3)}>
            Business App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 5)}>
            Agent Miner App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 4)}>
            Buyer App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 7)}>
            Off-Taker
          </MenuItem>
          
          <MenuItem onClick={this.handleClose.bind(this, 10)}>
            Transport App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 2)}>
            Veterinary App
          </MenuItem>
        </Menu>
      </AppBar> */
  );
}
