import React from "react";
import "./ManufacturingOnDemand.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/white-label1.jpg";
import PrivateLabelling from "../../images/brand-images/private-labelling.jpg";
import NewDevelopment from "../../images/brand-images/skills-concept-skills-education-learning-personal-development-competency-business-min.jpg";

// Main component for Manufacturing on Demand for Brands

export default function ManufacturingOnDemand() {
  return (
    <>
      {/* Banner section*/}
      <UpdatesBanner
        className="MoDBanner text-white"
        bannerText="Manufacturing on Demand for Brands"
      />
      {/* Section display lost of manufacturing on demand services */}
      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub specializes in providing contract manufacturing services, catering to diverse product needs. With a proven track record of expertise and reliability, We collaborate closely with brands to bring their concepts to life. From formulation to production and packaging, AgriProcessingHub ensures a seamless manufacturing process, delivering high-quality products that meet client specifications."
          title="Contract Manufacturing"
          src={ContractFarmingImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="AgriProcessingHub excels in private labeling services, allowing businesses to brand high-quality products as their own. Through this service, brands can leverage AgriProcessingHub's expertise to bring their unique products to market with a personalized touch.
          "
          title="Private Labelling"
          src={PrivateLabelling}
        />

        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description="AgriProcessingHub offers new product development services tailored to brands, helping them innovate and diversify their product offerings to meet evolving market demands."
          title="New Product Development"
          src={NewDevelopment}
        />
      </div>
      {/* Footer section */}
      <FooterComponent />
    </>
  );
}
