import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import DigitalTrust from "../../../src/images/checking-production-bottled-refined-sunflower-oil-food-factory-production-line-min.jpg";
import Trading from "../../../src/images/gbrAbout/aboutimg4.webp";
import Blockchain from "../../../src/images/aboutImg/business-success-concept-wooden-table-top-view-hands-protecting-wooden-figures-people (1).jpg";
import Farming from "../../../src/images/IMA.jpg";
import CareImg from "../../../src/images/processing-entreprenur.jpg";
import "react-awesome-slider/dist/styles.css";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default function AboutusComponent() {
  return (
    <>
      {/* Banner Section */}
      <UpdatesBanner
        className="about-us-banner"
        bannerText={[
          <span key="1">We enable processing entrepreneur to monetize </span>,
          <br key="2" className="d-none d-md-block"></br>,
          <span key="3">unutilized manufacturing capacity</span>,
        ]}
      />

      {/* Main Wrapper for About Page */}
      <div className="about-main-wrapper">
        
        {/* Section: Why We Exist */}
        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub generates social and economic impact by transforming Processing entrepreneurs to become digital-enterprise."
          title="Why We Exist"
          src={Farming}
        />

        {/* Section: Who We Are */}
        <AboutSubSection
          className="rowReverseAbout--mod aboutBgGrey"
          description="AgriProcessingHub is transforming the processing entrepreneurs landscape by introducing Plant Production Network that empower them to thrive and prosper."
          title="Who We Are"
          src={DigitalTrust}
        />

        {/* Section: Whom We Care About */}
        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub cares about processing entrepreneurs who want to monetize unutilized resources."
          title="Whom We Care About"
          src={CareImg}
        />

        {/* Section: What We Do */}
        <AboutSubSection
          className="rowReverseAbout--mod aboutBgGrey"
          description="We manage a plant production network for micro and small food manufacturing companies to monetize the unutilized capacity of processing units and get orders from buyers/brands."
          title="What We Do"
          src={Trading}
        />

        {/* Section: How We Do It */}
        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub builds a network of approved processing entrepreneurs who become our extended team to execute private-label and/or white-label orders as per the listed guidelines by our expert team."
          title="How We Do It"
          src={Blockchain}
        />

      </div>

      {/* Footer Section */}
      <FooterComponent />
    </>
  );
}
